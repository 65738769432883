import React from "react";
import BGS1 from "../../assets/images/backgrounds/1.svg";
import BGS2 from "../../assets/images/backgrounds/2.svg";
import BGS3 from "../../assets/images/backgrounds/1.1.svg";
import newBG1 from '../../assets/images/backgrounds/a.svg'

const BackgroungImg = (props) => {
	return (
		<>
			{/* <div className="bg bgscreen-1" >
				
			</div> */}
			{/* <div className="bg bgscreen-2">
				
			</div> */}
			{/* <div className="bg bgscreen-3">
				
			</div> */}
		</>
	);
};

export default BackgroungImg;
