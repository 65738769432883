import React from "react";
import Logo from "../assets/images/mainIcons/Logo.svg";
import Telegram from "../assets/images/mainIcons/Brands/telegram.svg";
import Twitter from "../assets/images/mainIcons/Brands/Twitter.svg";
import meta from "../assets/images/mainIcons/Brands/meta.svg";
import linkedin from "../assets/images/mainIcons/Brands/LinkedIn.svg";
import {
	TwitterLink,
	TelegramLink,
	MediumLink,
	LinkedinLink,
} from "../assets/externalLinks/externalLinks";

const Footer = (props) => {
	return (
		<footer>
			<div className="footer-top-wrapper">
				<div className="icon-name">
					<img src={Logo} alt="site Logo" />
					<p>SolClout Starter</p>
				</div>
				<div className="social-icons">
					<a target="_blank" href={TwitterLink}>
						<img src={Twitter} alt="social media icon" />
					</a>
					<a target="_blank" href={LinkedinLink}>
						<img src={linkedin} alt="social media icon" />
					</a>
					<a target="_blank" href={MediumLink}>
						<img src={meta} alt="social media icon" />
					</a>
					<a target="_blank" href={TelegramLink}>
						<img src={Telegram} alt="social media icon" />
					</a>
				</div>
			</div>
			<div className="footer-bottom-wrapper">
				<p>© SolClout Starter, 2021. All rights reserved</p>
				<div>
					<p>Privacy Policy</p>
					<p>Terms and Conditions</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
