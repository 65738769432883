export const outlineIcon = `<svg width="40" height="40" xmlns="http://www.w3.org/2000/svg" fill="none">
<title>iconHeader</title>
<g>
 <title></title>
 <rect x="0.05904" y="0.5" width="39" height="39" rx="19.5" fill="#5280E4" fill-opacity="0.1" stroke="#3C80FF" id="svg_1"/>
 <g>
  <path fill="#CEDAF5" d="m25.25,16.25l-0.75,0l0,-0.75c0,-0.59674 -0.2371,-1.16903 -0.659,-1.59099c-0.422,-0.42196 -0.9943,-0.65901 -1.591,-0.65901l-7.5,0c-0.59674,0 -1.16903,0.23705 -1.59099,0.65901c-0.42196,0.42196 -0.65901,0.99425 -0.65901,1.59099l0,9c0,0.5967 0.23705,1.169 0.65901,1.591c0.42196,0.4219 0.99425,0.659 1.59099,0.659l10.5,0c0.5967,0 1.169,-0.2371 1.591,-0.659c0.4219,-0.422 0.659,-0.9943 0.659,-1.591l0,-6c0,-0.59674 -0.2371,-1.16903 -0.659,-1.59099c-0.422,-0.42196 -0.9943,-0.65901 -1.591,-0.65901zm-10.5,-1.5l7.5,0c0.1989,0 0.3897,0.07902 0.5303,0.21967c0.1407,0.14065 0.2197,0.33142 0.2197,0.53033l0,0.75l-8.25,0c-0.19891,0 -0.38968,-0.07902 -0.53033,-0.21967c-0.14065,-0.14065 -0.21967,-0.33142 -0.21967,-0.53033c0,-0.19891 0.07902,-0.38968 0.21967,-0.53033c0.14065,-0.14065 0.33142,-0.21967 0.53033,-0.21967zm11.25,7.5l-0.75,0c-0.1989,0 -0.3897,-0.079 -0.5303,-0.2197c-0.1407,-0.1406 -0.2197,-0.3314 -0.2197,-0.5303c0,-0.1989 0.079,-0.3897 0.2197,-0.53033c0.1406,-0.14065 0.3314,-0.21967 0.5303,-0.21967l0.75,0l0,1.5zm0,-3l-0.75,0c-0.5967,0 -1.169,0.23705 -1.591,0.65901c-0.4219,0.42196 -0.659,0.99425 -0.659,1.59099c0,0.5967 0.2371,1.169 0.659,1.591c0.422,0.4219 0.9943,0.659 1.591,0.659l0.75,0l0,0.75c0,0.1989 -0.079,0.3897 -0.2197,0.5303c-0.1406,0.1407 -0.3314,0.2197 -0.5303,0.2197l-10.5,0c-0.19891,0 -0.38968,-0.079 -0.53033,-0.2197c-0.14065,-0.1406 -0.21967,-0.3314 -0.21967,-0.5303l0,-6.8775c0.24095,0.08476 0.49458,0.12788 0.75,0.1275l10.5,0c0.1989,0 0.3897,0.07902 0.5303,0.21967c0.1407,0.14065 0.2197,0.33142 0.2197,0.53033l0,0.75z"/>
 </g>
</g>

</svg>`;
